import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid } from '@mui/material'
import { TextField, MenuSelectorField, ModelAutocompleteField, DateTimeField } from '@front/squirtle'

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField name={'order_client_id'} label={I18n.t('order.id.alter')} />
    </Grid>
    <Grid item xs>
      <TextField name={'do.customerinfo.client_number'} label={I18n.t('user.id')} />
    </Grid>
    <Grid item xs>
      <TextField name={'loads.src_contact'} label={I18n.t('transport.passenger')} />
    </Grid >
    <Grid item xs>
      <TextField name={'do.auth.gsm'} label={I18n.t('phone.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name={'assigned_transport.driver.info.user_client_id'} label={I18n.t('driver.id')} />
    </Grid>
    <Grid item xs>
      <TextField name='operator.info.last_name' label={I18n.t('operator', { count: 1 })} />
    </Grid>
  </Grid>

const Row2 = ({ billing_status_list, order_status_list, TAXI_ENABLED, PACKAGE_ENABLED, transport_status_list, load_type }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={6}>
      <MenuSelectorField
        name='billing_status'
        label={I18n.t('billing.status.label', { count: 1 })}
        options={_.map(billing_status_list, status => ({ label: I18n.t(`billing.status.${status}`), value: status }))}
        multiple
        menuDecoratorProps={{ size: 'small' }}
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name='status'
        label={I18n.t('order.status.label', { count: 1 })}
        options={_.map(order_status_list, (status) => ({ label: I18n.t(`order.status.${status}`), value: status }))}
        multiple
      />
    </Grid >
    <Grid item xs>
      <MenuSelectorField
        name='assigned_transport.status'
        label={I18n.t('transportStatus.label', { count: -1 })}
        options={_.map(transport_status_list, (status) => ({ label: I18n.t(`transportStatus.${status}.user`), value: status }))}
        multiple
      />
    </Grid>
  </Grid >

const Row3 = ({ TAXI_ENABLED, PACKAGE_ENABLED, ...props }) =>
  <Grid container item xs={12} justifyContent={'center'} spacing={1}>
    <Grid item xs>
      <MenuSelectorField
        name={'commercial_package.load_type'}
        label={I18n.t('transport.type')}
        options={_.compact([
          !!TAXI_ENABLED ? { label: I18n.t('transport.user'), value: 'user' } : null,
          !!PACKAGE_ENABLED ? { label: I18n.t('transport.package'), value: 'package' } : null
        ])}
        noneLabel={I18n.t('all')}
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'commercial_package.commercial_package_id'}
        label={I18n.t('commercialpackage.label', { count: 1 })}
        model_name={'commercialpackage'}
        searchMode={'search'}
        labelKeys={['name']}
        loadOnFocus
        multiple
      />
    </Grid >
    <Grid item xs>
      <TextField name='assigned_transport.vehicle.info.plaque' label={I18n.t('vehicle.label', { count: 1 })} />
    </Grid>
  </Grid>

const Row4 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelAutocompleteField
        name={'do.commercial_formula'}
        label={I18n.t('commercial_formula.label', { count: 1 })}
        model_name={'commercialformula'}
        searchMode={'search'}
        labelKeys={['name']}
        loadOnFocus
        multiple
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'payment_types'}
        label={I18n.t('bank.payment_mode')}
        model_name={'paymenttype'}
        searchMode={'search'}
        config={{ sort: ['display_index ASC'] }}
        labelKeys={['name_translated']}
        multiple
      />
    </Grid>
    <Grid item xs>
      <TextField name='order_doc' label={I18n.t('document.b2c.id')} />
    </Grid>
    <Grid item xs>
      <TextField name='customer_ref' label={I18n.t('bank.b2c.customer_ref')} />
    </Grid>
  </Grid>

const Row5 = props =>
  <Grid container item xs={12} justifyContent={'center'} spacing={1}>
    <Grid item xs={3} >
      <DateTimeField
        name='requestedAt_start'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY',
        }}
        timeProps={{
          label: I18n.t('prefix.to'),
          minTime: null
        }}
        default_time='00:00'
      />
    </Grid>
    <Grid item xs={3} >
      <DateTimeField
        name='requestedAt_end'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY'
        }}
        timeProps={{
          label: I18n.t('prefix.to'),
          minTime: null
        }}
        default_time='23:59'
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container spacing={1} rowSpacing={1}>
    <Row1 {...props} />
    <Row2 {...props} />
    <Row3 {...props} />
    <Row4 {...props} />
    <Row5 {...props} />
  </Grid>

export default React.memo(SearchForm)
